<template>
  <el-dialog
      title="该款式所有订单"
      width="70%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-table
        :data="clothesList"
        border
        height="650"
        max-height="650"
        v-loading="loading"
        :default-sort="{prop: 'id', order: 'ascending'}"
        style="width: 100%">
      <el-table-column prop="clothesAllName" label="婚纱名" align="center"/>
      <el-table-column prop="cusName" label="姓名" align="center"/>
      <el-table-column prop="orderName" label="订单项目" align="center"/>
      <el-table-column prop="createDate" label="订单时间" align="center"/>
      <el-table-column prop="weddingDay" label="精确婚期" align="center"/>
      <el-table-column prop="proceedsCount" label="订单总价" align="center"/>
      <el-table-column prop="proceedsSpare" label="余款" align="center"/>
      <el-table-column prop="appointmentCity" label="订单城市" align="center"/>
      <el-table-column prop="orderDress" label="礼服师" align="center"/>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
name: "clothes-order-list",
  data() {
    return {
      loading:true,
      clothesList:[],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    searchDate:{
      type: Object,
      required: true,
      default(){return{}},
    }
  },
  created() {
    this.queryClothesList();
  },
  methods: {
    queryClothesList(){
      this.$axios({
        method: "GET",
        url: "/clothesAnalysis/queryClothesAllOrder",
        params: {
          styleId: this.searchDate.styleId,
          date:this.searchDate.dateString,
          shopId:this.searchDate.shopId,
          positionId:this.searchDate.positionId,
          tenantCrop:this.searchDate.tenantCrop,
        }
      }).then(response => {
        this.loading=false;
        this.clothesList=response.data.data.list
      })
    },

    close: function () {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>

</style>